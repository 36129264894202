<template>
    <layout>
        <div class="account-data mt-5 px-md-10">
            <div class="d-flex justify-space-between align-center">
                <h1 class="pr-10 heading-2">Notifications</h1>
                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none" depressed :to="{name: 'account.quote'}">Start new quote</v-btn>
            </div>
            <v-row class="main mt-5">
                <v-col cols="12" lg="7" class="pr-10">
                    <v-card class="px-10 py-4 mt-7" flat>
                        <v-card-text class="pa-0">
                            <h2 class="text-28-60 my-5">Notifications Settings</h2>
                            <p>These settings apply to all of your orders</p>
                            <div class="form">
                                <div class="notification-check-box px-5 py-3 d-flex justify-space-between mb-5" :class="{'selected': form.notification_status}">
                                    <span class="text-14-45">Status updates</span>
                                    <v-checkbox
                                        v-model="form.notification_status"
                                        class="ma-0 pa-0"
                                        :ripple="false"
                                        hide-details
                                    ></v-checkbox>
                                </div>
                                <div class="notification-check-box px-5 py-3 d-flex justify-space-between" :class="{'selected': form.notification_delivered}">
                                    <span class="text-14-45">Delivery confirmation</span>
                                    <v-checkbox
                                        v-model="form.notification_delivered"
                                        class="ma-0 pa-0"
                                        :ripple="false"
                                        hide-details
                                    ></v-checkbox>
                                </div>
                                <v-btn x-large class="secondary px-12 py-8 text-14 text-transform-none mt-8" depressed @click="update">Save changes</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" lg="5"></v-col>
            </v-row>
        </div>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/AccountLayout.vue'
export default {
    middleware: 'auth',
    components: {
        Layout,
    },
    data: () => ({
        form: {
            notification_status: false,
            notification_delivered: false,
        }
    }),
    mounted(){
        this.form.notification_status = this.$store.state.auth.user.notification_status;
        this.form.notification_delivered = this.$store.state.auth.user.notification_delivered;
    },
    methods: {
        update(){
            this.axios.put(process.env.VUE_APP_API + 'customers/v1/customer/' + this.$store.state.auth.user.id, this.form);
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
.account-data{
    .v-card{
        .form{
            .notification-check-box{
                border: 1px solid #CDE0FF;
                box-sizing: border-box;
                border-radius: 5px;
                .v-icon.notranslate{
                    color: #CDE0FF;
                }
                .v-icon.notranslate.primary--text{
                    color: #5496F0!important;
                }
            }
            .notification-check-box.selected{
                background: #EDF2F9;
            }
        }
    }
}

</style>
